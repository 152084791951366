import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function Seo({
	description,
	lang,
	meta,
	metaTitle,
	metaDesc,
	shareTitle,
	shareDesc,
}) {
	const data = useStaticQuery(graphql`
		query SeoQuery {
			siteSeo: sanitySettingsSeo(_id: { eq: "settingsSeo" }) {
				seo {
					metaTitle
					metaDesc
					shareTitle
					shareDesc
					shareGraphic {
						asset {
							gatsbyImageData(layout: CONSTRAINED)
						}
					}
				}
			}
			siteDetails: sanitySettingsGeneral(_id: { eq: "settingsGeneral" }) {
				siteTitle
			}
		}
	`);

	const title =
		metaTitle || (data.siteSeo && data.siteSeo.seo.metaTitle) || '';
	const desc = metaDesc || (data.siteSeo && data.siteSeo.seo.metaDesc) || '';
	const ogTitle =
		shareTitle || (data.siteSeo && data.siteSeo.seo.shareTitle) || '';
	const ogDesc =
		shareDesc || (data.siteSeo && data.siteSeo.seo.shareDesc) || '';
	const siteTitle = (data.siteDetails && data.siteDetails.siteTitle) || '';
	const ogImage = '';

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={metaTitle ? `%s | ${siteTitle}` : null}
			meta={[
				{
					name: `description`,
					content: desc,
				},
				{
					property: `og:title`,
					content: ogTitle,
				},
				{
					property: `og:description`,
					content: ogDesc,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: ogImage,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:image`,
					content: ogImage,
				},
				{
					name: `twitter:creator`,
					content: data.siteMetadata?.author || ``,
				},
				{
					name: `twitter:title`,
					content: ogTitle,
				},
				{
					name: `twitter:description`,
					content: ogDesc,
				},
			].concat(meta)}
		/>
	);
}

Seo.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};
