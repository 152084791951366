import React, { useRef, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Seo from '../components/Seo';

import video from '../images/versor.mp4';

const links = [
	{
		title: 'Enquire',
		text: 'hello@madebyverse.com',
		url: 'mailto:hello@madebyverse.com',
	},
	{
		title: 'Instagram',
		text: '@madeby.verse',
		url: 'https://www.instagram.com/madeby.verse/',
	},
];

const IndexPage = ({ data }) => {
	const page = data && data.page;

	const workList = useRef();
	const imageList = useRef();

	useEffect(() => {
		const workItems = workList.current.querySelectorAll('.work__item');
		workItems.forEach(function (el) {
			var number = el.getAttribute('data-image');
			var images = imageList.current.querySelectorAll(
				'.work-images__image'
			);
			el.addEventListener('mouseenter', function (e) {
				images.forEach(function (el) {
					el.classList.remove('is-active');
				});
				document
					.querySelector('[data-image="' + number + '"]')
					.classList.add('is-active');
			});
			el.addEventListener('mouseleave', function (e) {
				images.forEach(function (el) {
					el.classList.remove('is-active');
				});
			});
		});
	});

	return (
		<>
			<Seo
				metaTitle="Verse | Branding / Design & Creative / Custom Websites"
				shareTitle="Verse | Branding / Design & Creative / Custom Websites"
				metaDesc="Hi, we're Verse. We're a design and tech studio that brings brands to life on the world wide web."
				shareDesc="Hi, we're Verse. We're a design and tech studio that brings brands to life on the world wide web."
			/>
			<div className="-z-10 fixed inset-y-0 left-0 w-full lg:w-1/2 h-screen bg-pink overflow-hidden  text-white">
				<div className="absolute inset-0 overflow-hidden">
					<video
						autoPlay="autoplay"
						muted="muted"
						playsInline
						loop
						className="object-cover w-full h-full"
					>
						<source src={video} type="video/mp4" />
					</video>
				</div>
				<ul className="work-images" ref={imageList}>
					{page.featuredProjects.map((work, i) => {
						return (
							<li key={work._id}>
								<div
									className="work-images__image bg-black absolute inset-0 overflow-hidden"
									data-image={i}
								>
									{work.projectImage && (
										<GatsbyImage
											image={
												work.projectImage?.asset
													.gatsbyImageData
											}
											alt={work.title + ' project image'}
											className="object-cover h-full w-full"
										/>
									)}
									{work.projectVideo && (
										<video
											loop
											muted
											autoPlay
											playsInline
											className="absolute inset-0 h-full w-full object-cover"
										>
											<source
												src={
													work.projectVideo.asset.url
												}
												type={`video/${work.projectVideo.asset.extension}`}
											/>
										</video>
									)}
								</div>
							</li>
						);
					})}
				</ul>

				<div className="absolute inset-0 bg-gradient-to-t from-black"></div>
			</div>
			<section className="grid grid-cols-1 lg:grid-cols-2">
				<div className="sticky top-0 lg:h-screen overflow-hidden  text-white">
					<div className="h-full relative p-6 md:p-10 z-10 flex flex-col justify-between">
						<Link
							to="/"
							className="relative uppercase font-semibold tracking-widest"
						>
							Verse
						</Link>
						<ul className="h6 space-y-2 hidden lg:block">
							{links.map((link, i) => {
								return (
									<li key={i}>
										<span className="opacity-50">
											{link.title}:
										</span>{' '}
										<a href={link.url}>{link.text}</a>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className="min-h-screen p-6 md:p-10 lg:bg-black text-white space-y-8 flex flex-col justify-between scrol-auto">
					<div>
						<p className="text-3xl font-light ">
							Hi, we're Verse. We're a design and tech studio that
							brings brands to life on the world wide web.
						</p>
					</div>
					<div>
						<h2 className="h6">Featured Projects</h2>
						<ul className="work" ref={workList}>
							{page.featuredProjects.map((work, i) => {
								return (
									<li key={work._id}>
										<div
											className="work__item"
											data-image={i}
										>
											<div className="col-span-7">
												<h3 className="font-normal text-base">
													{work.title}
												</h3>
											</div>
											<div className="col-span-2">
												<p className="h6">
													{work.services
														?.map(
															(service) => service
														)
														.join(', ')}
												</p>
											</div>

											<div className="col-span-2 text-right">
												<p className="h6">
													{work.year}
												</p>
											</div>
											<div className="text-right">
												{work.link && (
													<a
														href={work.link}
														target="_blank"
														rel="noreferrer"
													>
														↗
													</a>
												)}
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
					<ul className="h6 space-y-2 block lg:hidden">
						{links.map((link, i) => {
							return (
								<li key={i}>
									<span className="opacity-50">
										{link.title}:
									</span>{' '}
									<a href={link.url}>{link.text}</a>
								</li>
							);
						})}
					</ul>
					<div>
						<p className="h6">&copy; Made by Verse PTY LTD</p>
					</div>
				</div>
			</section>
		</>
	);
};

export default IndexPage;

export const query = graphql`
	query HomeQuery {
		page: sanityHomePage {
			id
			title
			featuredProjects {
				_id
				services
				title
				year
				projectVideo {
					asset {
						url
						extension
					}
				}
				projectImage {
					asset {
						gatsbyImageData
					}
				}
				link
			}
		}
	}
`;
